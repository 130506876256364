import { Table, Button, Form, Input, message, Space, Card, Segmented } from "antd";
import { useEffect, useState, useRef } from "react";
import useTextFetch from "../hooks/useTextFetch";
import useUpdateEffect from "../hooks/useUpdateEffect";
import axios from 'axios';
import { REGEX, LAMBDAS } from "../literals";
import ConvertToFormUrlEncoded from "../utils";
import Modal from "react-modal";
import useUpdateLogger from "../hooks/useUpdateLogger";
import SelectBox from "devextreme-react/select-box";
import List from "devextreme-react/list";
import { templates } from "../assets/textTemplates";
import { interviewTemplates } from "../assets/interviewTemplates";
import { fcccTemplates } from "../assets/fcccTemplates";
import { customTemplates } from "../assets/customTemplates";
import { customDropdown } from "../assets/customDropdown";
import { queueDropdown } from "../assets/queueDropdown";
import { capacityDropdown } from "../assets/capacityDropdown";
import SelectSearch from "react-select-search";
import Select from "react-select";
import useAdjustFetch from "../hooks/useAdjustFetch";
import useAnnouncementFetch from "../hooks/useAnnouncementFetch";
const { TextArea } = Input;

const options = [
  {
    value:
      "The best way to apply is to visit our website at www.dcf.ks.gov, where you can find instructions to apply online.",
    label:
      "Apply: The best way to apply is to visit our website at www.dcf.ks.gov, where you can find instructions to apply online.",
  },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
  { value: "vanilla", label: "Chocholate" },
];

function ItemTemplate(data) {
  return <div>{data.Content}</div>;
}

var reg = /^\d+$/;
const textArea = "announcement";
const queueArea = "queue";
const announcementArea = "announcementType";

const customStyles = {
  content: {
    width: "50%",
    height: "50%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
Modal.setAppElement("#root");

const Announcements = (props) => {
  const [textValue, setTextValue] = useState("");
  const [selectedValue1, setSelectedValue1] = useState("General");
  const [selectedOption, setSelectedOption] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [announcementValue, setAnnouncementValue] = useState("");
  const [contactID, setContactID] = useState("");
  const [routingProfile, setRoutingProfile] = useState("");
  const [agentID, setAgentID] = useState("");
  const [xId, setXId] = useState("");
  const [searchMode, setSearchMode] = useState("contains");
  const [form] = Form.useForm();
  const [payLoad, setpayLoad] = useState(null);
  const { data, isFecthingData, error } = useAnnouncementFetch(
    LAMBDAS.ADJUST_ANNOUNCEMENT,
    payLoad,
    "POST",
    "application/json"
  );
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isSpanish, setIsSpanish] = useState(false);
  const [objValue, setObject] = useState({});
  const [isFirstLoad, setIsFirstLoad] = useState(false)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [progValue, setProgValue] = useState("select queue");
  const [announcementTypeVal, setAnnouncementTypeVal] = useState("statewide");
  const [programValue, setProgramValue] = useState();
  const [progLink, setProgLink] = useState("");
  const [caseNumber, setCaseNumber] = useState("")
  const [dataSource, setDataSource] = useState([{
    red: 0,
    green: 0,
    blue: 0,
    ir: 0,
    purple: 0,
    hsa: 0
  }])




  const columns = [
    {
      title: 'Red Queue',
      dataIndex: 'red',
      key: 'red',
    },
    {
      title: 'Green Queue',
      dataIndex: 'green',
      key: 'green',
    },
    {
      title: 'Blue Queue',
      dataIndex: 'blue',
      key: 'blue',
    },
    {
      title: 'IR Queue',
      dataIndex: 'ir',
      key: 'ir',
    },
    {
      title: 'Purple Queue',
      dataIndex: 'purple',
      key: 'purple',
    },
    {
      title: 'HSA Queue',
      dataIndex: 'hsa',
      key: 'hsa',
    },
  ];

  const validateAnnouncement = (announcement) => {
    let result = false;
    if (announcement.length > 0) {
      result = true;
    }
    return result;
  }

  function containsOnlyDigits(str) {
    return /^\d+$/.test(str);
  }

  const onFinish = () => {
    var newPayload = {
      announcement: form.getFieldValue(textArea),
      announcementType: form.getFieldValue(announcementArea),
      // agentID: agentID,
      // xID: xId,
    };

    console.log("NEW PAYLOAD: ", newPayload)
    if (validateAnnouncement(announcementValue)) {
      console.log("AMT AREA PAYLOAD: ", announcementValue);
      //setpayLoad(ConvertToFormUrlEncoded(form.getFieldsValue()));
      var newPayload = {
        announcement: form.getFieldValue(textArea),
        announcementType: form.getFieldValue(announcementArea),
      };
      setpayLoad(newPayload);
      setAnnouncementValue("")
      setAnnouncementTypeVal("statewide")
      form.setFieldsValue({
        announcement: "",
        announcementType: "statewide",
      });
      console.log("FORM VALUE: ", form);
      console.log("New Payload: ", newPayload);
      setIsRefreshing(true)
    }
  };

 

  window.addEventListener(
    "agentEvent",
    async function (e) {
      var agentID = e.detail.id;
      var xID = e.detail.xID;
      var routingProfile = e.detail.routingProfile;

      setAgentID(agentID);
      setXId(xID);
      setRoutingProfile(routingProfile);
    },
    false
  );

  useUpdateEffect(() => {
    console.log("Use Update Effect");
    // eslint-disable-next-line no-sequences
    if (announcementValue.length > 0) {
      console.log("Inside USE UPDATE EFFECT for sending announcement");
      console.log("Data: ", data);
      console.log("Error: ", error);

      switch ((data === null, error === null)) {
        case (false, true):
          console.log("INSIDE FALSE TRUE");
          console.log("BGEBGEGE");
          // announcement.success(`Text was successfully sent to ${form.getFieldValue(phone)}`);
          form.setFieldsValue({
            announcement: "",
            announcementType: "statewide",
          });
          break;
        case (true, false):
          console.log("INSIDE TRUE FALSE");
          // announcement.error(`Text failed to send to ${form.getFieldValue(phone)}`);
          form.setFieldsValue({
            announcement: "",
            announcementType: "statewide",
          });
          break;
        default:
          // announcement.success(`Text was successfully sent to ${form.getFieldValue(phone)}`);
          form.setFieldsValue({
            announcement: "",
            announcementType: "statewide",
          });
          break;
      }
    }
  }, [error, data]);

  useEffect(() => {
    if (!isFirstLoad || isRefreshing) {
      axios({
        method: 'POST',
        baseURL: LAMBDAS.CURRENT_METRICS,
        data: {},
      }).then((response) => {
        let finalData = []
        finalData.push(response.data)
        console.log("RESPONSE DATA CURRENT METRICS: ", finalData)
        setDataSource(finalData)

      }).catch((error) => {
        console.log(error);
      });
      setIsFirstLoad(true)
      setIsRefreshing(false)
    }

  }, [isFirstLoad, isRefreshing])

  useEffect(() => {
    console.log(
      "Inside ADJUST METRIC useEffect."
    );

    if (props.customerNumber.length > 0 && props.contactEnded == false) {
      console.log(
        "Setting SMS payload as customer phone is provided and contact has not ended"
      );
      console.log("AGGEWIV: ", props.customerNumber);
      console.log("AGGEWIV: ", phoneValue);
      console.log("JGEIGEN PAYLOAD: ", form.getFieldValue(textArea));

      try {
        console.log(props.customerNumber)
      } catch (error) {
        console.log("SMSERROR: ", error);
      }
    } else {
      console.log("Setting SMS payload as contact has ended");
      try {
        form.setFieldsValue({
          announcement: "",
          announcementType: "statewide",
        });
      } catch (error) {
        console.log("SMSERROR: ", error);
      }

      var event = new CustomEvent("refreshContact", {
        detail: { contactEnded: "" },
      });
      window.dispatchEvent(event);
    }
  }, [props.customerNumber, props.contactEnded, props.contactID]);

  // useEffect(()=>{
  //     console.log(selectedOption);
  // },[selectedOption])

  const onFinishFailed = () => {
    message.error("Please fill required fields.");
  };

  const onClear = () => {
    setObject({});
    setAnnouncementValue("");
    setAnnouncementTypeVal("statewide")
    setSelectedOption("");
    setIsSpanish(false);
    form.setFieldsValue({
      announcement: "",
      announcementType: "statewide",
    });
  };


  const handleUpdate = (e) => {
    setAnnouncementValue(e.target.value)
    var textAreaMessage = e.target.value;
    console.log("MESSAGE: ", textAreaMessage)
    var updatedMessage = selectedOption + "\n" + textAreaMessage;
    console.log(updatedMessage);
    setIsSpanish(false);
    form.setFieldsValue({
      announcement: textAreaMessage,
    });
  }


  function AnnouncementList(props) {
    console.log("PROPS CAPACITY LIST: ", props)
    function onDropChangeCapacity(event) {
      const { value } = event.target;
      console.log("ON Capacity Type CHANGE ENGAGED");

      // find the program with the current selected value
      const type = props.type.find((c) => c.name === value);
      console.log("capacity type value: ", type.value)



      if (type !== undefined) {
        setAnnouncementTypeVal(type.value)
        // use selected program object here
        form.setFieldsValue({
          announcementType: type.value
        });
      }
    }

    return (
      <>
        <input
          list="capacity"

          name="options"
          placeholder={announcementTypeVal}
          onChange={onDropChangeCapacity}
        />
        <datalist id="capacity">
          {props.type.map((type) => (
            <option
              key={type.value}
              data-value={type.value}
              value={type.name}
            />
          ))}
        </datalist>
      </>
    );
  }



  return (
    <div style={{ maxHeight: "430px", paddingLeft: '25px', paddingRight: '25px', overflow: "auto" }}>
     {/* <Card title="Adjust Capacity" hoverable> */}

        <Form
          disabled={isFecthingData}
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >

          <Space style={{ marginTop: '0px', marginBottom: '50px' }}>
          <label>Post </label>
            {/* <AnnouncementList type={capacityDropdown} /> */}
            <TextArea value={announcementValue} onChange={handleUpdate} rows={1} style={{ maxWidth: '100%' }} cols={100} placeholder={"announcement to add"} />
          </Space>

          {/* <textarea style={{ marginBottom: "10px" }} onChange={handleUpdate} name="postContent" rows={1} cols={40} /> */}

          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
              <Button htmlType="button" onClick={onClear}>
                Clear
              </Button>
              
            </Space>
          </Form.Item>
        </Form>
        {/* </Card> */}
    </div>
  );
};
export default Announcements;
